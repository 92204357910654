@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Roboto:wght@100;400;500&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
}

:root {
  --blue-dark: #072e6f;
  --blue: #317dde;
  --blue-medium: #0060d9;
  --font-opensans: "Open Sans";
  --font-roboto: "Roboto";
  --gray-low: #f3f3f3;
  --gray-medium: #e5e5e5;
  --gray: #8a8a8a;
  --gray-high: #c4c4c4;
  --gray-dark: #4f4f50;
  --turquoise: #7de5fe;
  --danger: #d9001a;
  --title: 1.875em;
  --subtitle: 1.25em;
  --body: 0.938em;
  --body-big: 2.5em;
  --title-big: 3.125em;
  --hight-container: 80vh;
  --green: #2ad70d;
}

.ant-layout {
  background: #fff !important;
}

/*---Barra customer--*/
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

:-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*--Standar --*/
@media only screen and (min-width: 1262px) {
  .hidden-xl {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1261px) {
  .hidden-sm {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.ant-switch-handle::before {
  background: #072e6f !important;
  background: var(--blue-dark) !important;
}
.ant-switch-checked {
  background: #e5e5e5 !important;
  background: var(--gray-medium) !important;
}
.ant-switch-handle {
  top: 1px !important;
}
.ant-switch-small {
  height: 14px !important;
}
.ant-drawer-body {
  padding: 20px !important;
}
.ant-drawer-header {
  padding: 5px 20px !important;
  border-bottom: none !important;
}
.ant-drawer-footer {
  border-top: none !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: white !important;
}
.ant-radio-inner::after {
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  display: table;
  width: 15px !important;
  height: 15px !important;
  background-color: #072e6f !important;
  background-color: var(--blue-dark) !important;
}
.ant-radio-inner {
  background-color: #c4c4c4 !important;
  background-color: var(--gray-high) !important;
  border-color: #c4c4c4 !important;
  border-color: var(--gray-high) !important;
}
/* .ant-dropdown-menu {
  padding: 10px !important;
} */

